import React from 'react'
import { graphql } from 'gatsby'
import BlockContent from '../components/block-content'
import Container from '../components/Container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'
import { Flex, Box } from 'rebass'
import PageHeadline from '../components/PageHeadline'
import styled from "styled-components"

const Img = styled.img`
max-width: 100%;
height: auto;
`

export const query = graphql`
  query ContactPageQuery {
    page: sanityPage(_id: { regex: "/(drafts.|)contact/" }) {
      title
      _rawBody
      _rawSecondbody
      _rawThirdbody
      images {
        asset {
          url
        }
      }
    }
  }
`

const ContactPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.page

  if (!page) {
    throw new Error(
      'Missing "Contact" page data. Open the studio at http://localhost:3333 and add "Contact" page data and restart the development server.'
    )
  }

  return (
    <Layout>
      <SEO title={page.title} />
      <Container>
        <PageHeadline>{page.title}</PageHeadline>
        <Flex mb='4' flexWrap="wrap">
          <Box width={[1, 2 / 3]} pr={[0, 5]}>
            <Box mb="5"><BlockContent blocks={page._rawBody || []} /></Box>
            {page._rawThirdbody && (
              <Box mb="4">
                <BlockContent blocks={page._rawThirdbody || []} />
              </Box>
            )}
          </Box>
          <Box width={[1, 1 / 3]}>
            <Box mb="3">
              {page.images.map((image, index) => (
                <Img src={image.asset.url} key={index} />
              ))}
            </Box>
            <BlockContent blocks={page._rawSecondbody || []} />
          </Box>
        </Flex>
      </Container>
    </Layout>
  )
}
ContactPage.defaultProps = {
  data: {
    page: {
      title: 'No title'
    }
  }
}
export default ContactPage
