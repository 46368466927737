import React from 'react'
import styled from 'styled-components'

const PageHeadlineStyle = styled.h1`
position: relative;
padding-bottom: 10px;

&::after {
  position: absolute;
  background: ${p => p.theme.colors.green};
  height: 3px;
  width: 100px;
  content: "";
  left: 0;
  bottom: 0;
}
`

export default function PageHeadline ({ children }) {
  return <PageHeadlineStyle>{children}</PageHeadlineStyle>
}
